import { Box, Heading, Text } from '@theme-ui/components';
import Vimeo from '@u-wave/react-vimeo';
import React from 'react';
import { Fade } from 'react-awesome-reveal';

interface Props {
  title?: string;
  credit?: string;
  videoId: string;
}

const Video = ({ credit, title, videoId }: Props) => {
  return (
    <Fade cascade fraction={0.25} triggerOnce>
      <Box sx={{ mb: [2, 2, 3, 3] }}>
        {title && (
          <Box mb={3}>
            <Heading as="h3" variant="text.h3">
              {title}
            </Heading>
          </Box>
        )}
        <Vimeo
          video={videoId}
          responsive
          color="#FFF"
          showPortrait={false}
          showTitle={false}
          showByline={false}
        />
        {credit && (
          <Box sx={{ my: 3 }}>
            <Text as="p" sx={{ fontSize: 3 }}>
              Video Credit: <strong>{credit}</strong>
            </Text>
          </Box>
        )}
      </Box>
    </Fade>
  );
};

export default Video;
