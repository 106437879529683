import { Box, Container, Flex, Heading, Text } from '@theme-ui/components';
import SEO from 'components/atoms/Seo';
import Video from 'components/atoms/Video';
import Layout from 'components/organisms/Layout';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import * as React from 'react';
import { Fade } from 'react-awesome-reveal';
import YouTube from 'react-youtube';
import { IProject } from 'types/HighScream.types';

const Project = ({
  data: {
    gallery: { nodes: images },
  },
  pageContext: { title, subTitle, content, heroVideoId, youTubeVideoId },
}: {
  pageContext: IProject;
  data: {
    gallery: {
      nodes: {
        id: string;
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      }[];
    };
  };
}) => {
  return (
    <Layout>
      <SEO />
      <Flex
        sx={{
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          pb: [5, 5, 5, 5],
          px: 3,
        }}
      >
        <Fade cascade fraction={0.25}>
          <Heading as="h1" sx={{ mb: 2 }}>
            {title}
          </Heading>
        </Fade>
        <Fade cascade fraction={0.25}>
          <Heading as="h2">{subTitle}</Heading>
        </Fade>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          maxWidth: 1920,
          mx: 'auto',
          mb: 4,
          px: [2, 2, 3, 3],
          img: {
            pb: [2, 2, 3, 3],
          },
          '.gatsby-image-wrapper-constrained': {
            display: 'block',
          },
        }}
      >
        {heroVideoId && <Video videoId={heroVideoId} />}
        {youTubeVideoId && (
          <Box
            sx={{
              position: 'relative',
              pb: '56.25%',
              pt: 30,
              height: 0,
              overflow: 'hidden',
              maxWidth: 1920,
              mb: [2, 2, 3, 3],

              'iframe, .video-container object, .video-container embed': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },
            }}
          >
            <YouTube
              opts={{ playerVars: { color: 'white' } }}
              videoId={youTubeVideoId}
            />
          </Box>
        )}

        {images.map(({ id, childImageSharp }) => (
          <Box key={id}>
            <Fade cascade fraction={0.25} triggerOnce>
              <GatsbyImage image={childImageSharp.gatsbyImageData} alt="" />
            </Fade>
          </Box>
        ))}
      </Flex>
      {content && (
        <Container>
          <MDXRenderer>{content}</MDXRenderer>
        </Container>
      )}
    </Layout>
  );
};

export const projectQuery = graphql`
  query ProjectImagesQuery($slug: String) {
    gallery: allFile(
      filter: {
        relativeDirectory: { eq: $slug }
        name: { regex: "/^((?!placeholder|Still_Placeholder).)*$/" }
      }
      sort: { fields: name }
    ) {
      nodes {
        id
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`;

export default Project;
